

import { format, formatISO, parseISO } from "date-fns";
import { Vue, Component, Watch } from "vue-property-decorator";

import { ReportService } from "@/services/report";

import { TaskService } from "@/services/task";
import {
  GroupTaskDTO,
  TaskEmployeeDTO,
  TaskCustomerDTO
} from "@/services/task/task.dto";

interface Task extends TaskCustomerDTO {
  assignedEmployee: string;
}

enum READER_NOTE_OPTIONS {
  NONE = "None",

  PERMANENTLY_CLOSED = "Permanently closed",

  DISCONNECTED = "Disconnected",

  METER_NOT_INSTALLED = "Meter not installed",

  METER_NOT_AVAILABLE = "Meter not available",

  OTHER = "Other"
}

@Component
export default class Customers extends Vue {
  file!: File;
  loading = false;
  search = "";
  groupName = "";
  resetTaskId = "";
  photoLinkSrc = "";
  tempTask!: TaskCustomerDTO;
  showResetTaskDialog = false;
  showCustomerDetails = false;
  showUploadPhotoDialog = false;
  tasks: TaskCustomerDTO[] = [];
  employees: TaskEmployeeDTO[] = [];
  isReaderNoteVisible = false;
  selectedReaderNoteOption = READER_NOTE_OPTIONS.NONE;
  readerNoteOptions = Object.values(READER_NOTE_OPTIONS);

  data() {
    return {
      file: null,
      tempCustomerDetails: {},
      showCustomerDetails: false,
      showUploadPhotoDialog: false,
      showResetTaskDialog: false,
      customers: [],
      resetTaskId: "",
      photoLinkSrc: "",
      headersForCustomersTable: [
        {
          text: "Customer Name",
          value: "customer.bpName"
        },
        {
          text: "BP Number",
          value: "customer.bpNumber"
        },
        {
          text: "Meter Number",
          value: "customer.deviceSerialNumber"
        },
        {
          text: "Reading Taken On",
          value: "dateOfPhotoTaken"
        },
        {
          text: "Employee Assigned",
          value: "assignedEmployee"
        },
        {
          text: "Current MR",
          value: "meterReading"
        },
        {
          text: "Door Closed",
          value: "wasDoorLocked"
        },
        {
          text: "Reader Note",
          value: "readerNote"
        },
        {
          text: "Mobile Number",
          value: "customer.contactNumber"
        },
        {
          text: "",
          value: "action"
        }
      ]
    };
  }

  created() {
    this.$store.commit("liveData/setSelectedActivity", 3);
    this.$store.commit("liveData/setSelectedActivityName", "Customers");

    if (!this.$route.query.groupName) {
      this.$router.replace("/groups");
    } else {
      this.groupName = this.$route.query.groupName as string;
      this.getTasksOfAGroup();
    }
  }

  @Watch("selectedReaderNoteOption")
  onChangeReaderNoteOption() {
    this.isReaderNoteVisible =
      this.selectedReaderNoteOption === READER_NOTE_OPTIONS.OTHER;
  }

  async getTasksOfAGroup() {
    this.loading = true;
    try {
      const tasksAndEmployeesOfAGroup: GroupTaskDTO = await TaskService.getTasksOfAGroup(
        {
          groupName: this.$route.query.groupName as string,
          area: this.$route.query.area as string,
          postalCode: this.$route.query.postalCode as string
        }
      );
      this.tasks = tasksAndEmployeesOfAGroup.tasks.map(task => ({
        ...task,
        assignedEmployee: (
          tasksAndEmployeesOfAGroup.assignedEmployees.find(
            employee => employee.employeeId === task.employeeId
          ) || {}
        ).name
      }));
      this.employees = tasksAndEmployeesOfAGroup.assignedEmployees;
    } catch (err) {
      console.log(err);
    }
    this.loading = false;
  }

  openEditTaskDialog(task: TaskCustomerDTO) {
    this.tempTask = { ...task };
    if (this.tempTask.readerNote) {
      if (
        Object.values(READER_NOTE_OPTIONS).includes(
          this.tempTask.readerNote as READER_NOTE_OPTIONS
        )
      ) {
        this.selectedReaderNoteOption = this.tempTask
          .readerNote as READER_NOTE_OPTIONS;
      } else {
        this.selectedReaderNoteOption = READER_NOTE_OPTIONS.OTHER;
      }
    }
    if (this.tempTask.photoLink) {
      let photoLink: string = this.tempTask.photoLink
        .substring(this.tempTask.photoLink.indexOf("file/d/"))
        .replace("file/d/", "");
      photoLink = photoLink.substring(0, photoLink.indexOf("/view"));

      this.photoLinkSrc = `https://drive.google.com/uc?id=${photoLink}&export=download`;
      console.log(this.photoLinkSrc);

      this.showCustomerDetails = true;
    } else {
      console.log(this.tempTask);
    }
  }

  deleteTaskData() {
    this.tempTask.photoLink = "";
    this.tempTask.meterReading = undefined;
    this.tempTask.wasDoorLocked = false;
    this.tempTask.dateOfPhotoTaken = undefined;
    this.tempTask.readerNote = "";
    this.photoLinkSrc = "";
    this.selectedReaderNoteOption = READER_NOTE_OPTIONS.NONE;
  }

  closeEditTaskDialog() {
    this.showCustomerDetails = false;
  }

  upload() {
    const url = URL.createObjectURL(this.file);
    console.log(url);
    this.photoLinkSrc = url;
    this.showUploadPhotoDialog = false;
  }

  async updateTaskDetails() {
    try {
      const formData = new FormData();
      formData.append("employeeId", this.tempTask.employeeId);
      formData.append("image", this.file);
      formData.append("wasDoorLocked", this.tempTask.wasDoorLocked + "");
      formData.append("dateOfPhotoTaken", formatISO(new Date()));
      formData.append(
        "readerNote",
        this.tempTask.readerNote || READER_NOTE_OPTIONS.NONE
      );
      if (this.tempTask.meterReading) {
        formData.append("meterReading", this.tempTask.meterReading.toString());
      }
      const updatedTask: TaskCustomerDTO = await TaskService.updateTask(
        this.tempTask.taskId,
        formData
      );
      this.showCustomerDetails = false;
      this.showUploadPhotoDialog = false;
      this.deleteTaskData();

      const index: number = this.tasks.findIndex(
        (elem: TaskCustomerDTO) => elem.taskId === this.tempTask.taskId
      );
      if (index > -1) {
        this.tasks[index] = { ...updatedTask };
      }
    } catch (err) {
      console.log(err);
    }
  }

  async resetTask() {
    try {
      const resetTask: TaskCustomerDTO = await TaskService.resetTask(
        this.resetTaskId
      );
      const index: number = this.tasks.findIndex(
        (elem: TaskCustomerDTO) => elem.taskId === this.resetTaskId
      );
      if (index > -1) {
        this.tasks[index] = { ...resetTask };
      }
      this.showResetTaskDialog = false;
    } catch (err) {
      console.log(err);
    }
  }

  formatDate(date: string | undefined) {
    return date ? format(parseISO(date), "dd-MM-yyyy HH:mm") : "";
  }

  goToCustomer(task: TaskCustomerDTO) {
    this.$router.push("/customer/" + task.customer.customerId);
  }

  openImageInNewTab() {
    if (this.photoLinkSrc) {
      window.open(this.photoLinkSrc, "_blank");
    }
  }

  async generateReport() {
    try {
      await ReportService.generateReport({
        groupName: this.$route.query.groupName as string,
        area: this.$route.query.area as string,
        postalCode: this.$route.query.postalCode as string
      });
      alert(
        "Report will be generated shortly. You can view the report in the reports tab"
      );
    } catch (err) {
      console.log(err);
    }
  }
}
